<template>
  <div class="home page">
    <Hero />
    <div class="flexible">
      <MyProfile />
      <MySkills hidden />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import MyProfile from "@/components/MyProfile.vue";
import MySkills from "@/components/MySkills.vue";

export default {
  name: "HomeView",
  components: {
    Hero,
    MyProfile,
    MySkills,
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles' as s;

.home {
  img {
    width: 3rem;
  }

  text-align: center;
  border-radius: 1rem;

  .flexible {
    width: min(100%, 60rem);
    margin: 0 auto;
    //   @include s.breakpointMax(medium) {
    //     display: flex;
    //     flex-direction: row;
    //     gap: var(--gap, 1rem);
    //     --gap: 0;
    //   }

    background-color: s.$light;
  }
}
</style>
