<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav class="flex">
      <div class="logo flex">
        <router-link to="/">
          <img src="@/assets/images/BZHLogo_Circle.png" alt="" srcset="" />
        </router-link>
      </div>

      <ul v-show="!mobile" class="navigation flex">
        <NavbarLink :pathName="'home'" :icon="'house'" :text="'Home'" />
        <NavbarLink hidden :pathName="'projects'" :icon="'code'" :text="'Projects'" />
        <NavbarLink :pathName="'blog'" :icon="'pen-nib'" :text="'Blog'" />
        <NavbarLink :pathName="'about'" :icon="'user'" :text="'About'" />
        <NavbarLink hidden :pathName="'resume'" :icon="'file'" :text="'Resume'" />
      </ul>

      <div class="icon">
        <transition name="fade" mode="out-in">
          <fai
            @click="toggleMobileNav"
            v-show="mobile && iconBars"
            :class="{ 'icon-active': mobileNav }"
            icon="fa-solid fa-bars"
            size="xl"
            fixed-width
          />
        </transition>
      </div>
      <div class="icon">
        <transition name="fade" mode="out-in">
          <fai
            @click="toggleMobileNav"
            v-show="mobile & !iconBars"
            :class="{ 'icon-active': mobileNav }"
            icon="fa-solid fa-x"
            size="xl"
            fixed-width
          />
        </transition>
      </div>

      <transition name="anti-mobile-nav">
        <div
          @click="toggleMobileNav"
          v-show="mobileNav"
          class="invisible-clickaway"
        ></div>
      </transition>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav flex">
          <NavbarLink
            @click="toggleMobileNav"
            :pathName="'home'"
            :icon="'house'"
            :text="'Home'"
          />
          <NavbarLink
            hidden
            @click="toggleMobileNav"
            :pathName="'projects'"
            :icon="'code'"
            :text="'Projects'"
          />
          <NavbarLink
            @click="toggleMobileNav"
            :pathName="'blog'"
            :icon="'pen-nib'"
            :text="'Blog'"
          />
          <NavbarLink
            @click="toggleMobileNav"
            :pathName="'about'"
            :icon="'user'"
            :text="'About'"
          />
          <NavbarLink
            hidden
            @click="toggleMobileNav"
            :pathName="'resume'"
            :icon="'file'"
            :text="'Resume'"
          />
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
import NavbarLink from "@/components/TheNavbarLink.vue";

export default {
  name: "navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: true,
      iconBars: true,
      mobileNav: null,
      windowWidth: null,
    };
  },
  components: {
    NavbarLink,
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
      this.iconBars = !this.iconBars;
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 640) {
        this.mobile = true;
        this.iconBars = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles' as s;

header {
  position: fixed;
  z-index: 9000;
  width: 100%;
  top: 0;
  transition: 0.5s ease all;

  background: transparentize(s.$xdbold, 0.05);
  box-shadow: 0px 1px 5px s.$dark, 0px 1px 5px s.$dark;

  padding: 0;

  nav {
    position: relative;
    flex-direction: row;
    padding: 0.5rem 1rem;
    transition: 0.5s ease all;
    width: min(100%, 64rem);
    margin: 0 auto;
    // background-image: linear-gradient(170deg,
    //                                 #f0f7ff 0%,
    //                                 #5e95cb 15%,
    //                                 #236bb0 30%,
    //                                 #08437c 45%,
    //                                 #00203e 85%,
    //                                 #000408 100%);

    @include s.breakpoint(large) {
      border-radius: 0 0 1rem 1rem;
    }

    ul {
      font-weight: bold;
      list-style: none;
      text-decoration: none;
    }

    .logo {
      align-items: center;
      margin-left: 0.5rem;

      img {
        width: 3rem;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    .icon {
      display: block;
      position: absolute;
      z-index: 9999;

      width: 2rem;
      border: 0;
      top: 1.5rem;
      right: 0.5rem;

      transition: 0.5s ease all;

      .fade-enter-from,
      .fade-leave-to {
        opacity: 0;
        transform: rotate(-90deg);
      }
      .fade-enter-active {
        transition: 0.5s ease all;
      }
      .fade-leave-active {
        transition: 0.5s ease all;
      }
    }

    .invisible-clickaway {
      position: fixed;
      z-index: 1000;
      inset: 0 70% 0 0;

      background: transparentize(s.$light, 1);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      --gap: 2em;

      position: fixed;
      z-index: 1000;
      inset: 0 0 0 30%;

      padding: min(20vh, 10rem) 1em;
      margin: 0;

      background: transparentize(s.$dark, 0.1);

      @supports (backdrop-filter: blur(1rem)) {
        background: transparentize(s.$dark, 0.4);
        backdrop-filter: blur(0.5rem);
      }
    }

    .anti-mobile-nav-enter-active,
    .anti-mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .anti-mobile-nav-enter-from,
    .anti-mobile-nav-leave-to {
      transform: translateX(100vw);
    }

    .anti-mobile-nav-enter-to {
      transform: translateX(0);
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(100vw);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
}

.scrolled-nav {
  nav {
    padding: 0.15rem 1rem;

    .icon {
      top: 1rem;
      transition: 0.5s ease all;
    }
  }
}

// @include s.breakpoint(small) {
//   .navigation {
//     --gap: 2em;

//     position: fixed;
//     z-index: 1000;
//     inset: 0 0 0 30%;

//     flex-direction: column;
//     padding: min(20vh, 10rem) 1em;
//   }

//   .mobile-nav-toggle {
//     display: block;
//     position: absolute;
//     z-index: 9999;
//     background: transparentize(s.$bold, 1);
//     color: s.$light;
//     width: 2rem;
//     border: 0;
//     top: 2rem;
//     right: 2rem;
//   }
// }

// Colors as hex codes */
$color-primary-0: #236bb0; // Main Primary color */
$color-primary-1: #d9e8f7;
$color-primary-2: #5e95cb;
$color-primary-3: #08437c;
$color-primary-4: #011931;

$color-secondary-1-0: #3830bb; // Main Secondary color (1) */
$color-secondary-1-1: #dedcf8;
$color-secondary-1-2: #706ad2;
$color-secondary-1-3: #171186;
$color-secondary-1-4: #060334;

$color-secondary-2-0: #18b57e; // Main Secondary color (2) */
$color-secondary-2-1: #d7f7ec;
$color-secondary-2-2: #57cea4;
$color-secondary-2-3: #008053;
$color-secondary-2-4: #003221;
</style>
