<template>
  <div class="profile-container">
    <div class="profile">
      <h3>Brian Z. Hamilton</h3>
      <p>Web Developer</p>
    </div>
    <div class="links-and-certs">
      <div class="social-links">
        <a class="link" href="https://github.com/brianzhamilton/">
          <div class="link-text">
            <fai icon="fa-brands fa-github" size="3x" fixed-width />
            <h4>Github</h4>
          </div>
        </a>
        <a class="link" href="https://www.linkedin.com/in/brianzhamilton/">
          <div class="link-text">
            <fai icon="fa-brands fa-linkedin" size="3x" fixed-width />
            <h4>LinkedIn</h4>
          </div>
        </a>
      </div>
      <div class="certifications">
        <a class="link" href="https://www.credly.com/badges/bc62483d-bee7-4151-a06c-d93fdca9db6b/public_url">
          <img src="@/assets/images/AplusCertLogo.png" alt="A+ Certification Logo">
        </a>
        <a class="link" href="https://www.credly.com/badges/b49d6c73-5900-471d-a729-d5cadb867410/public_url">
          <img src="@/assets/images/JavaCertLogo.png" alt="A+ Certification Logo">
        </a>
      </div>
    </div>
    <div class="about-this-website">
      <h2>Need A Web Developer?</h2>
      <p>
        While a good demonstration of my abilities, this website is a work in
        progress&mdash;it and my skills continue to improve day by day.
      </p>
      <p>
        I made this website with Vue.js and a lot of help from YouTube and Stack Overflow.
        It's a lot of spaghetti code, but I try to go back and refine it once I get it 
        working. In the words of Kent Beck:
        <ul>
          <li>Make it work</li>
          <li>Make it right</li>
          <li>Make it fast</li>
        </ul>
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@use "@/styles" as s;

.profile-container {
  background-color: s.$bold;
  color: s.$light;

  .profile {
    padding: 1rem;

    img {
      border-radius: 100%;
      width: min(100%, 20rem);
      margin: auto;
    }
    h3 {
      margin: 0 auto;
    }
    p {
      margin: 0 auto;
    }
  }

  .links-and-certs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 1rem auto;
    
    .social-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    }

    .certifications {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .link {
      width: 6rem;
      aspect-ratio: 1;
      margin: .5rem;
      padding: .5rem;
      color: s.$light;
      background-color: s.$dbold;
      border-radius: 1rem;
      border: .15rem solid s.$xdbold;

      .link-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          line-height: 0;
          padding: 0;
          margin: 1rem auto;
        }
      }

      &:hover {
        color: s.$light;
        border-color: s.$light;
      }
      
      img {
        width: 4.5rem;
        padding: .05rem;
        margin-top: .3rem;
      }
    }  
  }

  .about-this-website {
    padding: 0.5rem 0;
    background-color: s.$dbold;

    h2 {
      text-indent: 1rem;
    }

    p {
      width: clamp(28ch, 80%, 80ch);
      margin-inline: auto;
      text-align: left;
    }
  }

  // @include s.breakpointMax(medium) {
  //   // width: 30%;

  //   .links-and-certs {
  //     display: block;
      
  //     .social-links {
  //       display: block;
  //       width: 100%;

  //     }
  //     .certifications {
  //       display: block;
  //       width: 100%;
  //     }
  //   }
  // }
}
</style>