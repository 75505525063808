<template>
  <div>
    <Navbar />
    <router-view v-slot="{ Component }">
      <transition name="slide" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer />
  </div>
</template>

<script>
// imports
import Navbar from "@/components/TheNavbar.vue";
import Footer from "@/components/TheFooter.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
@use '@/styles' as s;

* {
  box-sizing: border-box;
}

html {
  background-color: s.$xdbold;
  //  background-image: linear-gradient(173deg, s.$dark 0%, s.$color-primary-3 30%, s.$color-primary-4 80%, s.$color-primary-2 100%);
  color: s.$light;
  font-family: s.$font;
  font-weight: 400;
  font-size: s.$fs0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  height: calc(100% + 193px);
}

h1 {
  font-size: s.$fs5;
  font-family: s.$fontHead;
  letter-spacing: 0.1rem;
  word-spacing: 0.25rem;
}

h2 {
  font-size: s.$fs3;
  font-family: s.$fontHead;
  letter-spacing: 0.1rem;
  word-spacing: 0.2rem;
}

H3 {
  font-size: s.$fs1;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  word-spacing: 0.05rem;
}

h4,
p,
button {
  letter-spacing: 0.01rem;
  word-spacing: 0.1rem;
  line-height: 1.5rem;
  font-size: s.$fs0;
}

a {
  text-decoration: none;
  color: s.$light;
}
a:hover {
  text-decoration: underline;
  color: s.$comp;
}

.page {
  margin: 0 auto;
  margin-top: 6.5rem;
  width: min(100%, 60rem);
}

.emph {
  text-transform: uppercase;
  color: s.$comp;
}

.text-chunk {
  display: inline-block;
}

.flex {
  @include s.breakpointMax(small) {
    display: flex;
  }
  gap: var(--gap, 1rem);
}

.link {
  display: inline-block;
  color: s.$lbold;
  width: fit-content;
  margin: 0.25rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  border: 0.15rem solid s.$bold;
  background-color: s.$xdbold;

  p {
    font-size: s.$fs-1;
    font-weight: 700;
    line-height: 0;
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: none;
    color: s.$light;
    border-color: s.$light;
  }

  &.router-link-active,
  &.router-link-exact-active {
    color: s.$light;
    background-color: s.$dbold;
    border-color: s.$lbold;
  }
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-2vw);
  opacity: 0;
}
.slide-enter-active {
  transition: 0.25s ease-out all;
}
.slide-leave-active {
  transition: 0.25s ease-in all;
}

// Colors as hex codes */
$color-primary-0: #236bb0; // Main Primary color */
$color-primary-1: #d9e8f7;
$color-primary-2: #5e95cb;
$color-primary-3: #08437c;
$color-primary-4: #011931;

$color-secondary-1-0: #3830bb; // Main Secondary color (1) */
$color-secondary-1-1: #dedcf8;
$color-secondary-1-2: #706ad2;
$color-secondary-1-3: #171186;
$color-secondary-1-4: #060334;

$color-secondary-2-0: #18b57e; // Main Secondary color (2) */
$color-secondary-2-1: #d7f7ec;
$color-secondary-2-2: #57cea4;
$color-secondary-2-3: #008053;
$color-secondary-2-4: #003221;
</style>
