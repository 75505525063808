import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/projects",
    name: "projects",
    // route level code-splitting
    // this generates a separate chunk (projects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "projects" */ "../views/ProjectsView.vue");
    },
    children: [
      {
        path: "name-selector",
        name: "name-selector",
        component: function () {
          return import(/* webpackChunkName: "name-selector" */ "../views/nestedProjectsViews/NameSelectorView.vue");
        }
      },
      {
        path: "name-generator",
        name: "name-generator",
        component: function () {
          return import(/* webpackChunkName: "name-generator" */ "../views/nestedProjectsViews/NameGeneratorView.vue");
        },
        children: [
          {
            path: "main-java",
            name: "main-java",
            component: function () {
              return import(/* webpackChunkName: "main-java" */ "../views/nestedProjectsViews/nestedNameGeneratorViews/MainJavaView.vue");
            }
          },
          {
            path: "name-java",
            name: "name-java",
            component: function () {
              return import(/* webpackChunkName: "name-java" */ "../views/nestedProjectsViews/nestedNameGeneratorViews/NameJavaView.vue");
            }
          },
          {
            path: "person-java",
            name: "person-java",
            component: function () {
              return import(/* webpackChunkName: "person-java" */ "../views/nestedProjectsViews/nestedNameGeneratorViews/PersonJavaView.vue");
            }
          },
          {
            path: "place-java",
            name: "place-java",
            component: function () {
              return import(/* webpackChunkName: "place-java" */ "../views/nestedProjectsViews/nestedNameGeneratorViews/PlaceJavaView.vue");
            }
          },
          {
            path: "animal-java",
            name: "animal-java",
            component: function () {
              return import(/* webpackChunkName: "animal-java" */ "../views/nestedProjectsViews/nestedNameGeneratorViews/AnimalJavaView.vue");
            }
          },
        ]
      },
    ]
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
    },
  },
  {
    path: "/resume",
    name: "resume",
    // route level code-splitting
    // this generates a separate chunk (resume.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "resume" */ "../views/ResumeView.vue");
    },
    children: [
      {
        path: "work",
        name: "work",
        // route level code-splitting
        // this generates a separate chunk (work.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "work" */ "../views/nestedResumeViews/WorkView.vue");
        },
      },
      {
        path: "college",
        name: "college",
        // route level code-splitting
        // this generates a separate chunk (college.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "college" */ "../views/nestedResumeViews/CollegeView.vue");
        },
        children: [
          {
            path: "fc-transcript",
            name: "fc-transcript",
            component: function () {
              return import(/* webpackChunkName: "fc-transcript" */ "../views/nestedResumeViews/nestedCollegeViews/FCTranscriptView.vue");
            }
          },
          {
            path: "itcc-transcript",
            name: "itcc-transcript",
            component: function () {
              return import(/* webpackChunkName: "itcc-transcript" */ "../views/nestedResumeViews/nestedCollegeViews/ITCCTranscriptView.vue");
            }
          },
        ]
      },
      {
        path: "skills",
        name: "skills",
        // route level code-splitting
        // this generates a separate chunk (skills.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "skills" */ "../views/nestedResumeViews/SkillsView.vue");
        },
      },
    ]
  },
  // {
  //   path: "/work",
  //   name: "work",
  //   // route level code-splitting
  //   // this generates a separate chunk (work.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "work" */ "../views/WorkView.vue");
  //   },
  // },
  // {
  //   path: "/college",
  //   name: "college",
  //   // route level code-splitting
  //   // this generates a separate chunk (college.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "college" */ "../views/CollegeView.vue");
  //   },
  //   children: [
  //     {
  //       path: "fc-transcript",
  //       name: "fc-transcript",
  //       component: function () {
  //         return import(/* webpackChunkName: "fc-transcript" */ "../views/nestedViews/FCTranscriptView.vue");
  //       }
  //     },
  //     {
  //       path: "itcc-transcript",
  //       name: "itcc-transcript",
  //       component: function () {
  //         return import(/* webpackChunkName: "itcc-transcript" */ "../views/nestedViews/ITCCTranscriptView.vue");
  //       }
  //     },
  //   ]
  // },
  {
    path: "/blog",
    name: "blog",
    // route level code-splitting
    // this generates a separate chunk (blog.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "blog" */ "../views/BlogView.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
