<template>
  <div class="footer">
    <div class="content">
      <p>Copyright &copy; 2023 Brian Z. Hamilton</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@use '@/styles' as s;

.footer {
  width: 100%;
  color: s.$lbold;
  background-color: s.$xdbold;

  .content {
    transition: .5s ease all;
    width: min(100%, 64rem);
    margin: 0 auto 0;
    padding: 1rem;

    text-align: center;

    p {
      font-style: italic;
      font-size: s.$fs-2;

      a {
        text-decoration: underline;
      }
    }
  }
}
</style>