<template>
  <div class="skills-container">
    <div class="technology-section">
      <div class="technology-section-title">
        <h3>Technologies Used to Make This Website</h3>
        <h5>(that also have a corresponding Font Awesome icon)</h5>
      </div>
      <div class="logos">
        <Logo class="html5-logo" icon="fa-brands fa-html5" />
        <Logo class="css3-logo" icon="fa-brands fa-css3" />
        <Logo class="sass-logo" icon="fa-brands fa-sass" />
        <Logo class="js-logo" icon="fa-brands fa-js" />
        <Logo class="vuejs-logo" icon="fa-brands fa-vuejs" />
        <Logo class="node-logo" icon="fa-brands fa-node" />
        <Logo class="font-awesome-logo" icon="fa-brands fa-font-awesome" />
        <Logo class="github-logo" icon="fa-brands fa-github" />
        <Logo class="git-logo" icon="fa-brands fa-git-alt" />
      </div>
    </div>
    <div class="button-container">
      <a class="project-button" href="">
        <fai icon="fa-solid fa-code" fixed-width />
        <span>My Projects</span>
      </a>
      <a
        class="project-button"
        href="https://www.dropbox.com/s/qmfbvxk8su7rrso/resume.pdf?dl=0"
        target="_blank"
      >
        <fai class="files" icon="fa-solid fa-file" fixed-width />
        <span>My Resume</span>
      </a>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/SkillTechLogo.vue";

export default {
  name: "MySkills",
  components: {
    Logo,
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles' as s;

.skills-container {
  background-color: s.$dbold;
  color: s.$light;

  .technology-section {
    background: s.$xdbold;
    color: s.$light;
    padding: 1rem;

    .technology-section-title {
      margin: 1rem auto;

      h3 {
        margin: 0.5rem auto;
        padding: 0;
      }

      h5 {
        margin: 0.5rem auto;
        padding: 0;
      }
    }

    .logos {
      display: inline-block;
      justify-content: center;
      margin: 1rem auto;

      .html5-logo {
        color: #e84e27;
      }

      .css3-logo {
        color: #2965f1;
      }

      .sass-logo {
        color: #ce679a;
      }

      .js-logo {
        color: #f0db4f;
      }

      .vuejs-logo {
        color: #41b883;
      }

      .node-logo {
        color: #41b883;
      }

      .font-awesome-logo {
        color: #1c7ed6;
      }

      .github-logo {
        color: #f5f5f5;
      }

      .git-logo {
        color: #f34f29;
      }
    }
  }

  .button-container {
    display: inline-block;

    width: 100%;
    margin: 0 auto;
    padding: 2rem;

    .project-button {
      display: inline-block;
      margin: 0.25rem;
      padding: 0.5rem;
      color: s.$lbold;
      border-radius: 0.5rem;
      border: 0.15rem solid s.$bold;
      background-color: s.$xdbold;
      font-weight: 700;
      text-transform: uppercase;
      word-spacing: 0.25rem;

      span {
        margin-left: 0.65rem;
      }

      &:hover {
        text-decoration: none;
        color: s.$light;
        border-color: s.$light;
      }
    }
  }
}
</style>
