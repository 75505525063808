<template>
  <li>
    <router-link class="link" :to="{ name: pathName }">
      <span aria-hidden="true">
        <fai class="fa-icon" :icon="'fa-solid fa-' + icon" fixed-width />
        &ndash;
      </span>
      {{ text }}
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    pathName: String,
    icon: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles' as s;

li {
  text-transform: uppercase;

  .link {
    font-weight: bold;
    list-style: none;
    text-decoration: none;

    transition: 0.5s ease all;
    padding-bottom: 0.25rem;
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 0.15rem solid transparent;

    &:hover {
      color: s.$light;
      border-color: s.$light;
    }

    @include s.breakpoint(medium) {
      span {
        display: none;
      }
    }

    .fa-icon {
      transition: 0.8s ease all;
    }
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: s.$lbold;

    &.router-link-active,
    &.router-link-exact-active {
      color: s.$light;
      border-color: s.$light;
    }

    [aria-hidden="true"] {
      font-weight: 750;
    }
  }
}
</style>
