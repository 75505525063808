<template>
  <fai size="2x" fixed-width />
</template>

<script>
export default {

}
</script>

<style>

</style>