<template>
  <div class="foreground">
    <div class="hero background" id="background">
      <div class="content flex">
        <img src="@/assets/images/portrait.jpg" alt="" srcset="" />
        <div class="intro">
          <h1>Brian Z. Hamilton</h1>
          <p class="life-goal">
            <span class="text-chunk">Seeking <span class="emph">truth</span></span>
            <span class="text-chunk">Being <span class="emph">kind</span></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@use '@/styles' as s;

.foreground {
  background: transparent;
  min-height: 100vh;
  width: 100%;
  position: relative;

  .background {
    display: flex;
    position: fixed;
    top: 5vmax;
    left: 0;
    right: 0;
    bottom: 0;
    width: min(100%, 60rem);
    height: 100vh;
    z-index: -1;
    align-items: center;
    margin: auto;
  }
}

.hero {
  background: s.$xdbold;
  color: s.$light;

  .content {
    width: min(100% - 2rem, 60rem);
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    .intro {
      text-align: left;
      width: fit-content;
      margin: auto auto auto 1rem;

      h1 {
        width: fit-content;
        display: inline-block;
        margin: 0 auto;
      }

      .life-goal {
        font-style: italic;
        width: 100%;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
      }
    }

    img {
      width: clamp(5rem, 50%, 18rem);
      border-radius: 50%;
      margin: auto 1rem auto auto;
    }

    @include s.breakpoint(small) {
      .intro,
      img {
        text-align: center;
        margin: auto;

        .life-goal {
          justify-content: center;
        }
      }
    }
  }
}
</style>
