import { createApp, VueElement } from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Solid icons in use: 
// faHouse, faUser, faPenNib, faBars, faX, faCode, faFile
import { 
  faHouse,  faUser, faPenNib, 
  faBars,   faX,    faCode, 
  faFile,   faGraduationCap,
  faBriefcase
} from '@fortawesome/free-solid-svg-icons';

// Brand icons in use: 
// faGithub, faLinkedin, faHtml5, faCss3, faSass, faJs, faVuejs, faNode
import {  
  faGithub, faLinkedin, faHtml5, 
  faCss3,   faSass,     faJs, 
  faVuejs,  faNode,     faFontAwesome, 
  faGitAlt 
} from '@fortawesome/free-brands-svg-icons';


library.add(
  faHouse,  faUser, faPenNib, 
  faBars,   faX,    faCode, 
  faFile,   faGraduationCap,
  faBriefcase
  );
library.add(
  faGithub, faLinkedin, faHtml5, 
  faCss3,   faSass,     faJs, 
  faVuejs,  faNode,     faFontAwesome, 
  faGitAlt
  );

createApp(App)
.component('fai', FontAwesomeIcon)
.use(router)
.mount("#app");
